<template>
  <div class="box-modal-wrapper box-form-success-modal">
    <div class="container">
      <div class="box-modal-body d-flex justify-content-between align-items-center flex-column">
        <div></div>
        <div class="sucess-modal-content-wrapper">
          <div class="sucess-modal-icon-wrapper d-flex justify-content-center align-items-center flex-column">
            <div class="sucess-modal-icon d-flex justify-content-center align-items-center">
              <check/>
            </div>
            <p class="sucess-modal-icon-title">Submetido com Sucesso!</p>
          </div>
        </div>
        <div class="box-modal-body-button-wrapper">
          <button type="button" class="generic-btn red-gradient big-text" @click="closeModal()">Voltar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import check from "@/components/svg/check.vue";

export default {
  name: "form-success-modal",
  components: {
    check
  },
  methods: {
    closeModal() {
      let element = document.getElementsByClassName("box-form-success-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      }
    },
  }
}


</script>