<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="74" height="57" viewBox="0 0 74 57">
    <path fill="#50AF00" d="M37.5 67.375L20.125 50 14.208 55.875 37.5 79.167 87.5 29.167 81.625 23.292z" transform="translate(-152 -255) translate(27 202) translate(111 30)"/>
  </svg>
</template>

<script>
  export default {
    name: "check"
  }
</script>