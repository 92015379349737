<template>
  <div>
    <div class="reserved-area-top-box-wrapper">
      <div class="container">
        <div class="reserved-area-top-box d-flex justify-content-center align-items-center flex-column">
          <p class="reserved-area-top-title">Olá {{ this.$root.userInfo.name }},</p>
          <p class="reserved-area-top-subtitle">Deixa-nos a tua mensagem ou envia-nos email. Seremos breves na resposta</p>
          <a href="mailto:info@boxpt.com" class="reserved-area-top-link">info@boxpt.com</a>
        </div>
      </div>
    </div>
    <div class="reserved-area-page">
      <div class="container">
        <div class="d-xl-flex">
          <div class="reserved-area-page-column">
            <reservedAreaMenu/>
          </div>
          <div class="reserved-area-page-column">
            <FormulateForm id="reserved-area-contact-form" class="reserved-area-contact-form" name="reserved-area-contact-form" v-model="values" @submit="submitContactsHandler">
              <div class="reserved-area-form-group-wrapper">
                <div class="reserved-area-form-group">
                  <div class="form-field">
                    <FormulateInput type="text" name="Name" label="Nome" placeholder="Insira o seu nome" v-model="name" validation="required" />
                  </div>
                  <div class="form-field">
                    <FormulateInput type="email" name="Email" label="Email" placeholder="Insira o seu email" v-model="email" validation="required" />
                  </div>
                  <div class="form-field">
                    <FormulateInput type="text" name="Subject" label="Assunto" placeholder="Insira o assunto" v-model="subject" validation="required" />
                  </div>
                  <div class="form-field">
                    <FormulateInput type="textarea" name="Message" label="Mensagem" placeholder="Deixar Mensagem" rows="6" v-model="message" validation="required" />
                  </div>
                </div>
              </div>
              <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
                <p>{{ formResponseMessage }}</p>
              </div>
              <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
                <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
              </div>
            </FormulateForm>

          </div>
        </div>
      </div>
    </div>

    <formSuccessModal/>

  </div>
</template>

<script>
import formSuccessModal from "@/components/modals/form-success-modal.vue";
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import axios from 'axios';
import qs from 'qs';
import router from '@/router';

export default {
  name: "contacts",
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  components: {
    reservedAreaMenu,
    formSuccessModal,
  },
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      name: '',
      email: '',
      subject: '',
      message: '',
      showMessage: false,
      formResponseMessage: '',
    };
  },

  methods: {
    openSuccessModal() {
      let element = document.getElementsByClassName("box-form-success-modal")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");

        setTimeout(function(){
          element.classList.remove("open");
        }, 5000);
      }
    },
    resetContactsHandler() {
      this.$formulate.reset('reserved-area-contact-form');
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
      this.showMessage = false;
      this.formResponseMessage = '';
    },
    submitContactsHandler() {
      const idForm = 2;

      const requestBody = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      };

      let authKey = window.$cookies.get("authKey");

      axios
          .post(process.env.VUE_APP_API_URL + 'forms/' + idForm, qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.resetContactsHandler();
              this.openSuccessModal();
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
  },
  created() {
    this.$root.pageTitle = this.$root.translations.definicoes;
  }
}
</script>